export interface HorizontalMenuBar {
  name: string,
  route: string
  url?: string
}

const routes = {
  HOME: 'home',
  REPORTS: 'reports',
  REPORTDOWNLOADS: 'reports/download',
  COMMUNICATIONPREFERENCES: 'communication-preferences-report',
  TEMPLATES: 'templates',
  DETAILEDSEARCH: 'detailedsearch',
  PATRONRENEWAL: 'patron-renewal',
  TEMPLATESDEFAULTS: 'administration/templates',
  FAILEDFILES: 'administration/failed-files',
  DEBUGFILES: 'administration/debug-files',
  // INVITEUSERS: 'administration/inviteusers',
  // MAPS: 'administration/maps',
  // ACCOUNTUNLOCK: 'administration/accountunlock',
  LISTCOMMUNICATIONS: 'advanced/list-communications',
  DYNAMICLISTS: 'advanced/dynamic-lists',
  PATRONDATABASE: 'advanced/dynamic-lists/mapping',
  AUDIENCELISTS: 'advanced/audience-lists',
  TASKS: 'administration/tasks',
  SETTINGS: 'settings',
  PASSWORDRESET: 'administration/password-reset',
  SMSMESSAGING: 'sms-messaging',
}

export const verticalMenuBarIcons: HorizontalMenuBar[] = [
  {
    name: 'Home',
    route: routes.HOME
  },
  {
    name: 'Reports',
    route: routes.REPORTS
  },
  {
    name: 'Report Downloads',
    route: routes.REPORTDOWNLOADS
  },
  {
    name: 'Communication Preferences',
    route: routes.COMMUNICATIONPREFERENCES
  },
  {
    name: 'Templates',
    route: routes.TEMPLATESDEFAULTS
  },
  {
    name: 'Detailed Search',
    route: routes.DETAILEDSEARCH
  },
  {
    name: 'SMS Messaging',
    route: routes.SMSMESSAGING
  },
  {
    name: 'Settings',
    route: routes.SETTINGS
  },

  {
    name: 'AdvancedDivider',
    route: ''
  },
  {
    name: 'List Communications',
    route: routes.LISTCOMMUNICATIONS
  },
  {
    name: 'Audience Lists',
    route: routes.AUDIENCELISTS,
    url: 'https://beta.messagebee.uniquelibrary.com/app/marketing/lists/audience'
  },
  {
    name: 'Dynamic Lists',
    route: routes.DYNAMICLISTS,
    url: 'https://beta.messagebee.uniquelibrary.com/app/marketing/lists/dynamic'
  },
  {
    name: 'Patron Renewal',
    route: routes.PATRONRENEWAL,
    url: 'https://beta.messagebee.uniquelibrary.com/app/integrations/patron-renewal'
  },
  {
    name: 'AdminDivider',
    route: ''
  },
  {
    name: 'Patron Database',
    route: routes.PATRONDATABASE
  },
  {
    name: 'Failed Files',
    route: routes.FAILEDFILES
  },
  {
    name: 'Debug Files',
    route: routes.DEBUGFILES
  },
  // {
  //   name: 'Invite Users',
  //   route: routes.INVITEUSERS
  // },
  // {
  //   name: 'Maps',
  //   route: routes.MAPS
  // },
  // {
  //   name: 'Account Unlock',
  //   route: routes.ACCOUNTUNLOCK
  // },
  {
    name: 'Tasks',
    route: routes.TASKS
  },
  {
    name: 'Password Reset',
    route: routes.PASSWORDRESET
  }
]
